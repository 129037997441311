<template>
    <router-view></router-view>
</template>

<script>
import { mapActions } from 'vuex'
import titleMixin from '../../mixin/title'
export default {
    name: 'Sale',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.common.1665802470469') }, // '线索'
    props: {},
    components: {},
    data() {
        return {}
    },
    created() {
        this.initData()
    },
    mounted() { },
    methods: {
        ...mapActions([
            'setCurrency',
            'setDomain'
        ]),
        initData() {
            this.setCurrency()
            this.setDomain()
        }
    },
    updated() { },
    beforeDestroy() { },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
